import { CustomTheme } from '@autone/ui';
import { Theme, useTheme } from '@mui/material';
import { alpha, experimentalStyled as styled } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { PropsWithChildren } from 'react';

import AuthFooter from './AuthFooter';
import AuthHeader from './AuthHeader';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundImage: `linear-gradient(${
    theme.palette.background.paper
  } 55%, ${alpha(theme.palette.primary.main, 0.15)})`,
}));

const MainStyle = styled('div')({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
});

export const DashedBorderLine = () => (
  <hr
    style={{
      height: '1px',
      backgroundImage:
        'linear-gradient(90deg, #CDDEFF, #CDDEFF 40%, transparent 40%, transparent 100%)',
      backgroundSize: '25px 1px',
      border: 'none',
    }}
  />
);

export const PageLayout = ({ children }: PropsWithChildren) => {
  const theme = useTheme<CustomTheme>();
  const { newAuthFlow } = useFlags();

  if (!newAuthFlow) {
    return children;
  }

  return (
    <RootStyle>
      <AuthHeader />
      <MainStyle theme={theme as unknown as Theme}>{children}</MainStyle>
      <AuthFooter />
    </RootStyle>
  );
};
